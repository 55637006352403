import {EuiSideNav, slugify} from "@elastic/eui";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";


const SideNav = () => {
  const navigate = useNavigate();
  const [selectedItemName, setSelectedItem] = useState("");
  const createItem = (name: string, data = {}) => {
    return {
      id: slugify(name),
      name,
      isSelected: selectedItemName === name,
      onClick: () => setSelectedItem(name),
      ...data,
    };
  };

  const navItems = [
    createItem('채널', {
      onClick: undefined,
      items: [
        createItem('채널 관리', {
          onClick: () => { navigate(`/channels`) },
        }),
      ],
    }),
    createItem('비디오', {
      onClick: undefined,
      items: [
        createItem('비디오 관리', {
          onClick: () => { navigate(`/videos`) },
        }),
      ],
    }),
    createItem('계정', {
      onClick: undefined,
      items: [
        createItem('광고주 관리', {
          onClick: () => { navigate(`/accounts`) },
        }),
      ],
    }),

    createItem('카테고리', {
      onClick: undefined,
      items: [
        createItem('카테고리 관리', {
          onClick: () => { navigate(`/categories`) },
        }),
      ],
    }),

    // createItem('검색어', {
    //   onClick: undefined,
    //   items: [
    //     createItem('검색어 관리', {
    //       onClick: () => {},
    //     }),
    //   ],
    // }),

    createItem('브랜드', {
      onClick: undefined,
      items: [
        createItem('브랜드 관리', {
          onClick: () => { navigate(`/brands`) },
        }),
      ],
    }),

    // createItem('통계', {
    //   onClick: undefined,
    //   items: [],
    // }),
  ]
  return (
    <EuiSideNav
      items={navItems}
      style={{width: 192}}
    />
  )
}

export default SideNav;